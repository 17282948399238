import React from 'react';
import loading from './assets/loading.svg';
import './App.css';
import { useState } from 'react';
import SVGIcon from 'react-svg-favicon';
import { DefaultIcon } from './favicon';

function App() {
  var [notifications, setNotifications] = useState(0);

  return (
    <div className="App">
      <SVGIcon sizes="any" type="image/svg+xml" media="">
        <DefaultIcon>
          {notifications > 0 && (
            <>
              <circle cx={70} cy={70} r={30} fill="red" />
              <text
                x={70}
                y={85}
                fontSize={50}
                fontFamily="monospace"
                textAnchor="middle"
              >
                {notifications}
              </text>
            </>
          )}
        </DefaultIcon>
      </SVGIcon>
      <header className="App-header">
        <img src={loading} className="App-logo" alt="logo" />
        <p>Coming Soon</p>
        <a
          className="App-link"
          href="/"
          rel="noopener noreferrer"
        >
          Home
        </a>
        <br></br>
        <button hidden onClick={() => setNotifications((n) => n + 1)}>
          Add notification
        </button>
        <br></br>
        <button hidden onClick={() => setNotifications((n) => 0)}>
          Reset notifications
        </button>
      </header>
    </div>
  );
}

export default App;
